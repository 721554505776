import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRocket } from "@fortawesome/pro-duotone-svg-icons";

const ContactForm = (props) => {
    const { title, subTitle, img } = props;
    return (
        <section className="contact-area pb-100 pt-100">
            <div className="container">
                <div className="section-title">
                    <h2>{title}</h2>
                    <p>{subTitle}</p>
                </div>

                <div className="row">
                    <div className="col-lg-6 col-md-12">
                        <div className="contact-image">
                            <img src={img} alt="contact" height={550} width={359}/>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="contact-form">
                            <form method="post" netlify-honeypot="bot-field" data-netlify="true" name="Contact Page Form" id={'contactForm'} action="/contact/success">
                              <input type="hidden" name="bot-field" />
                              <input type="hidden" name="form-name" value="Contact Page Form" />
                                <div className="row">
                                    <div className="col-lg-12 col-md-6">
                                        <div className="form-group">
                                            <input type="text" name="name" className="form-control" required placeholder="Your name *" />
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-md-6">
                                        <div className="form-group">
                                            <input type="email" name="email" className="form-control" required placeholder="Your email address *" />
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <select class="form-select" name="contact reason" aria-label="Reason for contact">
                                              <option value="No Answer" selected>Reason for contact</option>
                                              <option value="Talk About Work">Talk About Work</option>
                                              <option value="Old Friend">Catchup with an Old Friend</option>
                                              <option value="Food Request">Request more jam, honey, etc</option>
                                              <option value="Other">Other</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <textarea name="message" className="form-control" cols="30" rows="6" required placeholder="Write your message..." />
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-md-12">
                                        <button type="submit" className="default-btn">
                                            <FontAwesomeIcon icon={faRocket} className="flaticon-right" style={{width: '20px', height: '20px' }}/>
                                            Send Message <span></span>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ContactForm
