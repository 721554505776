import contactImg from '../../assets/images/astronaut-thumbs-up.svg'
import contactSuccessImg from '../../assets/images/astronaut-relaxed.svg'

export const pageHeader = {
  pageTitle: 'Contact',
  homePageText: 'Home',
  homePageUrl: '/',
  activePageText: 'Contact',
};


export const banner = {
  title: 'Want to chat?',
  subTitle: 'Reach out using the form below. Required fields are marked *',
  img: contactImg,
};


export const bannerSuccess = {
  title: 'Thank you for reaching out!',
  subTitle: 'I will get back to you as soon as I can.',
  img: contactSuccessImg,
};
