import React from 'react'
import Layout from "../../components/App/Layout"
import SEO from '../../components/App/SEO'
import NavbarDynamic from "../../components/App/NavbarDynamic"
import PageBanner from '../../components/Common/PageBanner'
import FooterMinimal from "../../components/App/FooterMinimal"
import ContactForm from '../../components/Contact/ContactForm'
import { banner, pageHeader } from '../../data/page-data/contactData';

const headerInfo = {
    title: 'Contact | BryanLokey.com',
    description: 'How to contact Bryan Lokey',
    keywords: 'contact,contact form',
    canonical: 'https://bryanlokey.com/contact/',
    ogImage: banner.img,
};

const Contact = () => {
    return (
        <div>
          <SEO {...headerInfo}/>
          <Layout>
            <NavbarDynamic />
            <PageBanner {...pageHeader}/>
            <ContactForm {...banner} />
            <FooterMinimal />
          </Layout>
        </div>
    );
}

export default Contact
